// 2、创建英文语言包对象
export default { // 西班牙语
    // 登录
    login: {
      language: 'Idioma',
      login: 'Iniciar sesión / Registro',
      usernameplaceholder: 'Por favor complete su número de teléfono',
      passwordplaceholder: 'Por favor ingrese su contraseña de inicio de sesión',
      Forgotpassword: 'Has olvidado tu contraseña',
      Determine: 'Acceder',
      rulesusername: 'El número de teléfono no puede estar vacío',
      rulespassword: 'La contraseña no puede estar vacía'
    },
    // 忘记密码
    Forgot: {
      title: 'Has olvidado tu contraseña',
      phone: 'número de teléfono',
      Newpassword: 'Nueva contraseña',
      Confirmationpassword: 'Contraseña de confirmación',
      code: 'Código de verificación',
      codes: 'código',
      Determine: 'Confirmar',
      rulesemail: 'El número de teléfono no puede estar vacío',
      emailplaceholder: 'Por favor, introduzca su número de teléfono', // 请输入电话号码
      newpassword: 'Por favor ingrese una nueva contraseña',
      rulespassword: 'Por favor, ingrese contraseña',
      torulespassword: 'Las dos contraseñas deben ser consistentes.',
      rulescode: 'El código de verificación no puede estar vacío.',
      Changecode: 'Por favor, primero introduzca su número de teléfono.'
    },
    // 注册
    register: {
      username: 'Nombre de usuario',
      phone: 'Número de teléfono',
      password: 'Contraseña',
      code: 'Código de verificación',
      Invitation: 'Código de invitación',
      Determine: 'Confirmar',
      phonecode: 'Código de verificación móvil',
      rulesusername: 'El nombre de usuario no puede estar vacío',
      rulesnumber: 'El número de teléfono no puede estar vacío',
      rulespassword: 'La contraseña no puede estar vacía',
      rulescode: 'El código de verificación no puede estar vacío.',
      rulesInvitation: 'El código de invitación no puede estar vacío',
      Changecode: 'Por favor, primero introduzca su número de teléfono',
      codes: 'código'
    },
    // 首页
    home: {
      top: 'Bienvenido de nuevo',
      platform: 'La plataforma funciona de forma segura.',
      timeday: 'DÍA',
      timehour: 'HORA',
      timemin: 'MINUTO',
      timesec: 'SEGUNDO',
      currencies: 'Monedas populares',
      SignIn1: 'Día 1',
      SignIn2: 'Día 2',
      SignIn3: 'Día 3',
      SignIn4: 'Día 4',
      SignIn5: 'Día 5',
      SignIn6: 'Día 6',
      SignIn7: 'Día 7',
      gift: 'Caja de regalo misteriosa',
      Sign: 'Regístrate',
      chick: 'Iniciado sesión hoy'
    },
    // 公告
    gonggao: {
      title: 'Anuncio'
    },
    // 公告详情
    gonggaodetails: {
      title: 'Detalles del anuncio'
    },
    // 签到
    SignIn: {
      title: 'Centro de inicio de sesión',
      profit: 'Gana recompensas',
      SignIn1: 'Día 1',
      SignIn2: 'Día 2',
      SignIn3: 'Día 3',
      SignIn4: 'Día 4',
      SignIn5: 'Día 5',
      SignIn6: 'Día 6',
      SignIn7: 'Día 7',
      gift: 'Caja de regalo misteriosa',
      Sign: 'Regístrate',
      chick: 'Iniciado sesión hoy'
    },
    // 签到记录
    CheckinRecord: {
      title: 'Registro diario de inicio de sesión'
    },
    // k线图
    diagram: {
      height: 'Altura',
      low: 'bajo',
      quantity: 'cantidad 24H',
      Recharge: 'Recargar',
      purchase: 'compra',
      min1: '1 minuto',
      min5: '5 minutos',
      min30: '30 minutos',
      hour1: '1 hora',
      dayk: 'Día k',
      Weekk: 'Semana k'
    },
    // 矿机运行
    Receive: {
      Remaining: 'Hora de inicio restante',
      Start: 'Comenzar',
      Click: 'Hacer clic para ver',
      Help: 'Centro de ayuda',
      Team: 'Equipo',
      Totalnumber: 'Número total de personas',
      Todays: 'Ganancias de hoy',
      available: 'Beneficios del siguiente nivel',
      Recharge: 'Recargar',
      Withdraw: 'retirar',
      friends: 'Invita a amigos a unirse para obtener recompensas.',
      qd: 'Comenzar', // 新增--启动
      yq: 'invitar', // 邀请
      lrtj: 'Estadísticas de ganancias', // 利润统计
      tjsj: 'Datos estadísticos', // 统计数据
      xj: 'informe detallado' // 细节
    },
    // 帮助中心
    Helpcenter: {
      title: 'Centro de ayuda'
    },
    // 帮助中心详情
    Helpcenterdetails: {
      title: 'Detalles del Centro de ayuda'
    },
    // 团队
    party: {
      title: 'Mi equipo',
      Totalnumber: 'Número total de personas',
      Todays: 'Recompensas de registro',
      available: 'Recargar recompensas',
      team1: 'equipo de nivel 1',
      team2: 'equipo de nivel 2',
      team3: 'equipo de nivel 3',
      Referral: 'Código de referencia',
      Share: 'Compartir enlace',
      Success: 'Replicar el éxito',
      Job: 'Título profesional',
      Income: 'Ingreso',
      Receive: 'Recibir',
      zczrs: 'Número total de inscripciones', // 注册总人数
      czzrs: 'Número total de recargas', // 充值总人数
      czyj: 'Reembolsos en compras de equipos', // 充值佣金
      zczje: 'Monto total de recarga' // 总充值金额
    },
    // 团队等级
    Grade: {
      title1: 'Nivel 1',
      title2: 'Nivel 2',
      title3: 'Nivel 3',
      Username: 'Nombre de usuario',
      Phone: 'Teléfono',
      Recharge: 'Recargar',
      qxz: 'Por favor seleccione', // 请选择 修改
      wcz: 'No recargado', // 未充值
      ycz: 'Recargado', // 有充值
      cpgq: 'Caducidad del producto', // 产品过期
      zzyx: 'Correr' // 正在运行
    },
    // 充值选择
    Recharge: {
      title: 'Canal de recarga',
      recharge: 'Recargar',
      amount: 'Monto de recarga',
      Please: 'Por favor ingresa el monto de la recarga',
      confirm: 'Confirmar',
      success: 'elige el éxito',
      less: 'El monto de la recarga no puede ser menor a uno'
    },
    // 充值详情
    Charge: {
      Address: 'Copiar dirección',
      reminder: 'Recordatorio de recarga',
      assets: 'No agregue activos a cuentas que no sean la cuenta oficial; de lo contrario, los activos no se recuperarán',
      amount: 'El importe de la transferencia debe estar en total conformidad con el importe real del pedido.',
      Upload: 'Enviar orden de recarga',
      Please: 'Por favor asegúrese de que la imagen esté seleccionada.',
      Success: 'Replicar el éxito',
      Remark: 'Observación: Recuerde cargar la captura de pantalla después de recargar'
    },
    // 充值记录
    Rechargerecord: {
      title: 'Registro de recarga',
      Date: 'Fecha',
      Amount: 'Cantidad',
      Channel: 'Canal',
      State: 'Estado',
      recharged: 'Ser recargado',
      successful: 'Recarga exitosa',
      Notrecharged: 'No recargado',
      shzt: 'Estado de auditoría', // 审核状态
      shz: 'en revisión' // 审核中
    },
    // 提现
    Withdraw: {
      title: 'Retirar',
      Amount: 'Cantidad',
      Available: 'Disponible',
      All: 'Todo',
      address: 'DIRECCIÓN',
      Submit: 'Entregar',
      Currently: 'Actualmente admite dos métodos de retiro',
      Bank: 'Horario de retiro bancario: los retiros se pueden realizar en cualquier momento de lunes a viernes',
      Arrival: 'Hora de llegada: prepárese según la hora de llegada del banco, generalmente entre 30 y 48 horas.',
      within: 'La hora de llegada es dentro de las 72 horas durante el período del subsidio de día libre de impuestos.',
      withdrawal: 'El monto del retiro no puede ser inferior a 15',
      receiving: 'Primero vincule la cuenta receptora antes de continuar con la operación.',
      min: 'Mín.',
      max: 'Máx.',
      fee: 'Tarifa'
    },
    // 采矿页面
    Mining: {
      title: 'Estrategia comercial',
      Power: 'Fuerza',
      progress: 'En curso',
      Computer: 'Potencia de la computadora',
      Purchasing: 'Precio de compra',
      Daily: 'Ingresos diarios de producción',
      Valid: 'Periodo válido',
      Days: 'Días',
      Recharge: 'Recargar',
      purchase: 'Comprar ahora', // purchase
      Miner: 'Minero',
      After: 'Después del vencimiento, el minero puede darte en cualquier momento.',
      Experience: 'Estrategia comercial',
      Price: 'Precio',
      profit: 'Beneficio del día',
      Start: 'Fecha de inicio',
      End: 'Fecha final',
      Agree: 'Aceptar',
      Escrow: 'Acuerdo de custodia',
      Artificial: 'Solución de minería de inteligencia artificial',
      Users: ' Los usuarios pueden minar comprando máquinas de minería o alquilando potencia de computación en la nube',
      Hash: 'Tasa de hash: 1TH/S-100TH/S ',
      Benefit: 'Beneficio: Obtenga el 80% de cada moneda producida, en el sistema se liquidará automáticamente en la cuenta del usuario.',
      Withdrawal: 'Retiro: Retiro en cualquier momento, 24 horas para retiro',
      experience: '(El minero de experiencias es solo para la experiencia del usuario)',
      Special: 'Nota especial',
      calculation: 'El cálculo de estos datos se basa en el precio de la moneda actual y se estima de acuerdo con los datos de la moneda del grupo de minería, y el sistema los convierte automáticamente a USD. Para obtener datos específicos de producción de moneda real, consulte la situación real para los datos de moneda verificados.',
      machine: 'Los ingresos por energía de las máquinas mineras y la computación en la nube pueden disminuir con el tiempo, y el ingreso anualizado específico puede estar sujeto al ingreso anualizado real en ese momento.',
      mining: 'Compre una máquina minera o alquile un plan de energía de computación en la nube y la tarifa de mantenimiento de custodia de la producción de moneda representa el 20% del costo del equipo de soporte de ajuste dinámico temprano. La cantidad esperada de moneda no será inferior al 70% por día. El cálculo actual se basa en el valor de producción más bajo para calcular los ingresos. La expectativa anualizada real es superior al 300%. Prevalecerá la cantidad real de moneda producida.',
      Currency: 'Currency production time T+1 (that is, within one day after the completion of nuclear delivery and custody, and deposit of pledged currency), please refer to the official actual official currency production date for details',
      Confirm: 'Confirmar',
      first: 'Confirme primero para aceptar el acuerdo de depósito en garantía',
      purchases: 'Número de compras',
      gmsl: 'Cantidad de compra',
      yjsy: 'Ingresos proyectados'
    },
    // 我的页面
    my: {
      Accumulated: 'Recompensas acumuladas',
      Today: 'Ganancias de hoy',
      total: 'los ingresos totales',
      available: 'Saldo disponible',
      wallet: 'Mi billetera',
      GPT: 'Mi GPT',
      center: 'Centro de ayuda',
      address: 'Dirección de billetera',
      envelope: 'Centro de actividades',
      Video: 'Función de vídeo',
      Download: 'Descarga de la aplicación',
      company: 'Sobre la empresa',
      Sign: 'Desconectar',
      Exit: 'Salir exitosamente',
      mykj: 'Mi robot', // 我的矿机 修改
	  yueb:"Yu'E Bao"
    },
    // 设置页面
    install: {
      title: 'Instalar',
      switch: 'cambiar de idioma',
      name: 'Modificar nombre',
      Password: 'Cambiar la contraseña',
      Phone: 'modificar telefono',
      binding: 'Enlace de aplicaciones'
    },
    // 上传头像
    Headimage: {
      Avatar: 'Subir avatar',
      Upload: 'Subir',
      image: 'La imagen cargada no puede estar vacía.'
    },
    // 修改名字
    ModifyName: {
      name: 'Modificar nombre',
      Determine: 'Confirmar',
      cannot: 'El nombre no puede estar vacío'
    },
    // 修改密码
    ChangePassword: {
      title: 'Cambiar la contraseña',
      Old: 'Contraseña de inicio de sesión antigua',
      New: 'Nueva contraseña de inicio de sesión',
      Confirm: 'confirmar Contraseña',
      Determine: 'Confirmar',
      oldpassword: 'Por favor ingrese la contraseña anterior',
      newpassword: 'Por favor ingrese una nueva contraseña',
      Confirmationpassword: 'Por favor, ingrese contraseña',
      twopasswords: 'Las dos contraseñas deben ser consistentes.'
    },
    // 修改手机号
    Phonenumber: {
      title: 'Número de teléfono',
      phone: 'Número de teléfono',
      code: 'Código de verificación móvil',
      codes: 'código',
      Determine: 'Confirmar',
      Phonerules: 'El número de teléfono no puede estar vacío',
      coderules: 'El código de verificación no puede estar vacío.',
      Please: 'Por favor, primero introduzca su número de teléfono.'
    },
    // 所有记录
    Mypurse: {
      title: 'My bolso',
      All: 'Todo',
      type: 'tipo',
      Recharge: 'Recargar',
      withdraw: 'Retirar',
      product: 'compra de producto',
      daily: 'ingreso diario',
      Direct: 'recompensa por referencia directa',
      envelope: 'Colección de sobres rojos',
      fixed: 'renta fija',
      check: 'registrarse',
      benefits: 'beneficios del equipo',
      Cancel: 'Cancelar',
      Theres: 'No hay mas',
      Loading: 'Cargando',
      System: 'Ajuste del sistema'
  
    },
    // 矿机状态
    product: {
      title: 'Comprar producto',
      progress: 'En curso',
      purchases: 'Número de compras',
      Computer: 'potencia de la computadora',
      price: 'Precio de compra',
      Daily: 'Ingresos diarios de producción',
      running: 'Correr',
      expired: 'Caducado',
      Expired: 'Caducado',
      start: 'hora de inicio',
      end: 'hora de finalización'
    },
    // 购买记录
    Purchaserecord: {
      title: 'Historial de compras',
      types: 'tipos de',
      product: 'nombre del producto',
      price: 'precio del producto',
      Daily: 'Regreso diario',
      start: 'hora de inicio',
      end: 'hora de finalización'
    },
    // 地址
    Address: {
      title: 'Dirección de billetera',
      already: 'Ya has añadido tu dirección. Si necesita modificarlo, comuníquese con el servicio al cliente para modificarlo.'
    },
    // 新增地址
    Addressdetails: {
      title: 'Añadir dirección',
      Address: 'DIRECCIÓN',
      Determine: 'Confirmar',
      empty: 'La dirección no puede estar vacía.',
      Successfully: 'Añadido exitosamente'
    },
    // 领取红包
    Redemption: {
      title: 'Código de canje',
      Please: 'Por favor ingresa el código de canje',
      Determine: 'Confirmar',
      Exchange: 'El código de intercambio no puede estar vacío'
    },
    // 视频介绍
    Video: {
      title: 'introducción en vídeo'
    },
    // 公司介绍
    company: {
      title: 'Sobre la empresa'
    },
    // 活动列表
    activity: {
      title: 'actividad',
      hot: 'producto caliente',
      discounted: 'productos con descuento'
    },
    // 绑定邮箱
    mail: {
      mailbox: 'Buzón',
      yzm: 'Código',
      srmail: 'Por favor, introduzca su dirección de correo electrónico', // 请输入您的电子邮件地址
      sryzm: 'enviar', // 请输入验证码
      sendcode: 'Enviar', // 发送验证码
      emptyemail: 'El correo electrónico no puede estar vacío.' // 电子邮件不能为空
    },
    // 利润
    profit: {
      jrsy: "Las ganancias de hoy", // 今日收益
      zcyqjl: 'Registrar recompensas por invitación', // 注册邀请奖励
      jqsy: 'Ingresos de la máquina', // 机器收益
      tdyjjl: 'Recompensas de comisiones de equipo', // 团队佣金奖励
      yxsr: 'Ingresos salariales mensuales', // 月薪收入
      lrze: 'Beneficio total', // 利润总额
      zzcyqjl: 'Recompensas totales por invitación de registro', // 总注册邀请奖励
      zjqsy: 'Ingresos totales de la máquina', // 总机器收益
      ztdyjjl: 'Recompensa de comisión total del equipo', // 总团队佣金奖励
      zyxsr: 'Ingresos salariales mensuales totales', // 总月薪收入
      jrzlr: "Beneficio total de hoy", // 今日总利润
      zlr: 'Beneficio tota' // 总利润
    },
    // 底部导航
    foot: {
      home: 'Hogar', // 首页
      yx: 'Compartir', // 运行
      jy: 'Robot', // 交易
      hd: 'Transacción', // 活动
      my: 'Mi' // 我的
    },
    // rechargettwo
    rechargettwo: {
      name: 'nombre',
      bank: 'banco',
      account: 'cuenta',
      qtjczjt: 'Por favor agregue una captura de pantalla de recarga',
      czje: 'Monto de recarga'
    },
    add: {
      addemail: 'Número de teléfono', // 电话号码
      addforbottom: 'Si la verificación del número de teléfono móvil falla, haga clic para contactar al servicio de atención al cliente oficial.',
      addforbottomer: 'Official customer service',
      addregtitle: 'Registro',
      addregts: 'Registro exitoso, redireccionamiento',
      addlogintitle: 'Bienvenido de nuevo'
    },
    addhome: {
        inbodyyi: 'Introducir',
        inbodyer: 'Recargar',
        inbodysan: 'Retirar',
        inbodysi: 'Compartir amigos',
        inbodywu: 'Equipo',
        inbodyliu: 'Tarea',
        inbodyxz: 'Descargar',
        inbodykf: 'Servicio',
        wallet: 'Mi billetera',
        walletyi: 'Activos totales',
        walleter: 'Saldo disponible',
        walletsan: 'Ganancia',
        walletsi: 'Ganancias de hoy',
        walletwu: 'Total de tokens OPT',
        walletliu: 'Valor total de OPT',
        walletqi: 'Valor de OPT',
        help: 'Centro de ayuda',
        bottom: `Los robots inteligentes ignoran la volatilidad del mercado, comprando automáticamente criptomonedas a 
        bajo precio en el intercambio A y vendiéndolas a un precio más alto en el intercambio B, generando ganancias las 24 horas del día.`,
        bottomer: 'Simply put, it means buying some tokens at a low price on one exchange and sellin',
        bottomtwo: 'Operación segura',
        bottomtwoday: 'Día',
        bottomtwoer: 'Ingresos acumulados',
        hezuotitle: 'Plataforma de Cooperación',
        hezuoyi: 'Binance',
        hezuoer: 'Kraken',
        hezuosan: 'KuCoin',
        hezuosi: 'Marca de bits',
        hezuowu: 'Gate.io',
        hezuoliu: 'Coinbase',
        hezuoqi: 'Bybit',
        hezuoba: 'OKX',
        hezuojiu: 'bitget',
        hezuoshi: 'Bitfinex',
        tcyi: 'Regalo misterioso',
        tcer: 'caja',
        tcsan: 'Último publicado',
        homeqd: 'Iniciar sesión',
        kefu: 'Contactar Servicio al Cliente',
        kfname: 'Servicio al cliente',
        gb: 'Cierre'
    },
    addsign: {
      titleyi: 'Tarea de inicio de sesión',
      yidaoqi: 'recibir recompensas dentro de 7 días',
      titleer: 'Registros continuos Duplica la recompensa',
      yidaoqier: 'Firmado en 1/7 días'
    },
    addrech: {
        tsyi: 'El valor de la recarga no puede exceder los 5.000',
        tser: 'Valor mínimo de carga',
        tsmessyi: 'Utilice USDT para recargar y disfrutar de un reembolso adicional. Por ejemplo, si el índice de bonificación es del 3%, si el usuario usa USDT para recargar 100USDT, puede obtener 103USDT.',
        tsmesser: 'Al completar la recarga de la cuenta utilizando cualquier método de pago, los usuarios pueden recibir recompensas en tokens OPT, puntos de lotería, recompensas en efectivo, etc. Preste atención a la información del anuncio de la empresa para obtener recompensas específicas.'
    },
    addbank: {
        bank: 'Banco',
        bank_title: 'Cuenta bancaria',
        wallet: 'USDT',
        wallettitle: 'Dirección de cartera',
        banktitle: 'Recargar',
        banktitlevalue: 'Introduzca el importe de la recarga',
        tsyi: 'El monto de la recarga no puede ser cero',
        tser: 'El valor de sobrecarga no puede ser inferior a',
        btn: 'Enviar recarga',
        tssan: 'Por favor sube la imagen primero',
        bankbtn: 'Confirmar recarga'
    },
    addwith: {
        address: 'Dirección de cartera',
        addresser: 'Dirección de cuenta bancaria',
        addresssan: 'Tipo de salida',
        addresssi: 'Banco',
        tsyi: 'El monto del retiro no podrá ser menor a',
        tser: 'El monto del retiro no debe exceder',
        tssan: 'El monto del retiro no podrá ser menor a',
        jiluyi: 'Salir del registro',
        jiluer: 'Fecha',
        jilusan: 'Cantidad',
        jilusi: 'Canal',
        jiluwu: 'Estado'
    },
    address: {
        Bitcoin: 'Revertir',
        message: 'Para la seguridad de su cuenta, vuelva a emitir el retiro 24 horas después de modificar la información de la cuenta.',
        messageer: 'Por favor, únete primero.',
        usdtyi: 'Por favor ingresa la dirección de tu billetera',
        usdter: 'Solo se pueden agregar direcciones TRC20',
        usdtsan: 'Email de contacto',
        usdtsi: 'Código de verificación de correo electrónico',
        addbankyi: 'Número de cuenta',
        addbanker: 'Ingresar cuenta',
        addbanksan: 'Nombre del banco',
        addbanksi: 'Introduzca el nombre del banco',
        addbankwu: 'Por favor, escriba su nombre',
        addbankliu: 'Por favor, escriba su nombre',
        addbankqi: 'Email de contacto',
        addbankba: 'Email de contacto',
        addbankjiu: 'Código de verificación de correo electrónico',
        addbankp: 'Para garantizar que los fondos de su retiro lleguen sin problemas a su cuenta. Por favor vincule su nombre real y cuenta bancaria. De lo contrario, es posible que no reciba el retiro.',
        tsyi: 'La cuenta bancaria no puede estar vacía.',
        tser: 'el número no puede estar vacío',
        tssan: 'El correo electrónico no puede estar vacío.',
        tssi: 'El código de verificación no puede estar vacío.',
        tswu: 'el nombre no puede estar vacío',
        tsliu: 'El formato del correo electrónico es incorrecto.',
        lxkf: 'Contactar Servicio al Cliente'
    },
    addtask: {
        title: 'Tarea',
        conright: 'Unirse',
        conrighter: 'Ahora',
        tsyi: 'Recibido',
        tser: 'Deshecho',
        titleer: 'Compartir',
        shareyi: 'Subir Enviar foto',
        shareer: 'Por favor cargue el certificado de uso compartido',
        sharesan: 'Entregar',
        sharets: 'La imagen aún no se ha subido.'
    },
    addturn: {
        start: '¿Cómo obtener puntos?',
        problenyi: 'Esta ronda de sorteo aún no está abierta, así que estad atentos.',
        problener: `1.Al comprar un robot por primera vez, recibirás 20 puntos.`,
        problenerer: ' 2.Cuando invites a un nuevo empleado a registrarse en una cuenta de SIGOPT y este compre un robot, recibirás 10 puntos.',
        problenersan: '3.Después de la primera compra del robot, recibirás 10 puntos por cada compra posterior del robot.',
        problensan: 'Consume 20 puntos para participar en un sorteo.',
        problensi: 'Consume 5 saldos para participar en un sorteo.',
        topboxyi: 'Cada vez que participas en la lotería, gastas 20 puntos.',
        topboxer: 'Puntos restantes',
        topboxsan: 'Número total de sorteos',
        btn: 'Registros de lotería',
        tsyi: 'Si los puntos son inferiores a 20 has sido transferido al saldo para participar en el sorteo',
        tser: 'Saldo inferior a 5 no puede participar',
        tssan: 'Los que tengan menos de 20 puntos no podrán participar',
        tssi: 'Saldo inferior a 5 no puede participar',
        tswu: 'El sorteo aún no ha comenzado, así que estad atentos.',
        tsliu: 'No hay puntos suficientes para participar',
        tsqi: 'El sorteo aún no ha comenzado, así que estad atentos.',
        title: 'Disco giratorio',
        Bitcoin: 'Consumo puntual',
        Bitcoiner: 'pegar'
    },
    addrece: {
        title: 'Equipo',
        tcyi: 'Copiar enlace',
        tcer: 'Compartir a',
        tsyi: 'Por favor recarga y activa tu cuenta primero',
        cztxsj: 'Datos del equipo',
        cztxsr: 'Ingresos del equipo'
    },
    addparty: {
        incomeyi: 'Reembolso no recibido',
        incomeer: 'Ingreso diario',
        incomesan: 'Recompensas promocionales',
        lbodyyi: 'Total / Válido',
        lbodyer: 'Recarga/Comisión',
        level: 'Nivel',
        sqyj: 'Cobrar comisión',
        levelteam: 'Equipo de nivel',
        jieshaotitle: 'Reglas específicas',
        jieshaoyi: 'Reglas de invitación',
        jieshaoer: 'Invita a más amigos a unirse a tu equipo y podrás obtener más recompensas por invitación y comisiones de equipo.',
        jieshaosan: 'Cada vez que invite a un nuevo amigo a comprar un robot comercial por primera vez, recibirá directamente una recompensa en efectivo en USDT.',
        jieshaosi: 'Cada vez que invite a un nuevo amigo a comprar un robot comercial por primera vez, recibirá directamente una recompensa en efectivo en USDT.',
        jieshaowu: 'Puede obtener el 10 % de los ingresos totales de los robots de los miembros de nivel 1, el 5 % de los ingresos totales de los robots de los miembros de nivel 2 y el 2 % de los ingresos totales de los robots de los miembros de nivel 3 como recompensas de comisión de equipo.',
        jieshaoliu: 'Cuando los usuarios activos de tu equipo de nivel 123 alcanzan números diferentes, puedes obtener bonificaciones y premios en diferentes etapas, como semanal y mensual.',
        thismonth: 'Este mes',
        lastmonth: 'El mes pasado',
        all: 'Todo',
        tsyi: 'Actualmente no hay comisiones disponibles por ingresos',
        gradeyi: 'Nivel 1 Número actual de personas activas',
        gradeer: 'Nivel 2 Número actual de personas activas',
        gradesan: 'Nivel 3 Número actual de personas activas',
        gradetitle: 'Nivel Equipo',
        levelall: 'Comisión de equipo',
        levelone: 'Equipo de Nivel 1',
        leveltwo: 'Equipo de Nivel 2',
        levelthree: 'Equipo de Nivel 3'
    },
    addpull: {
        pullyi: 'Tire hacia abajo para refrescar',
        puller: 'Déjalo ir y actualiza',
        pullsan: 'Cargando...'
    },
    addprofit: {
        title: 'Ganancia',
        datayi: 'Proporción de recompensa de registro',
        dataer: 'Relación de ingresos de la máquina',
        datasan: 'Ratio de comisión del equipo',
        datasi: 'Relación de ingresos salariales mensuales'
    },
    addmin: {
        content: 'Tasa de retorno',
        tcyi: 'Cálculo',
        tsyi: 'Actualmente no tienes la cantidad de máquinas compradas',
        tser: 'Ya no hay cantidad',
        tssan: 'Actualmente no tienes la cantidad de máquinas compradas',
        tssi: 'La cantidad de compra no puede ser cero.'
    },
    addgift: {
        nav: 'BTC/USDT',
        normal: 'Predicción',
        navrightyi: 'Historia',
        navrighter: 'Regla',
        qhyi: 'Modo normal',
        qher: 'Modo estable',
        msgyi: 'Alto',
        msger: 'bajo',
        msgsan: 'Volumen 24H',
        fxk: 'Política de privacidad financiera',
        bonex: 'Boleto',
        btwoyi: 'Monto de la apuesta:',
        datanull: 'Actualmente no hay datos disponibles',
        tcyi: 'Cantidad',
        tcer: 'Se espera que esté disponible después de la rentabilidad.',
        tcsan: 'Tasa de tramitación:',
        tcsi: 'Cantidad en USDT',
        tcsitwo: 'Saldo disponible:',
        tcwu: 'Entregar',
        tarbaryi: '1 minuto',
        tarbarer: '5 minuto',
        tarbarsan: 'Comercio',
        tabarsi: 'Transacciones históricas',
        tabarwu: 'Esperando transacción',
        tabarliu: 'Registro de la transacción',
        tsyi: 'Por favor, consulte primero el acuerdo de privacidad.',
        tser: 'La cantidad no puede estar vacía o ser cero',
        tssan: 'No puede ser inferior al mínimo de',
        htitle: 'Historia de los números ganadores',
        htsyi: 'Qué diablos está pasando',
        rtitle: 'Regla',
        Ttitle: 'Análisis de tendencia',
        tbodyyi: 'Predicción 28',
        tbodyer: '[Señal]',
        ycboyi: 'Planificador',
        ycboer: 'Boleto',
        ycbosan: 'Predecir',
        ycbosi: 'Resultado',
        ycbowu: 'ganar',
        ycboliu: 'perder',
        ycboqi: 'Número de compradores:',
        ycboba: 'Selección rápida',
        yctcyi: 'Orden de confirmación',
        yctcer: 'Boleto',
        yctcsan: 'Opciones de apuesta',
        yctcsi: 'Cantidad de apuesta única',
        yctcwu: 'Total del pedido',
        yctcliu: 'Cancelar',
        yctcqi: 'Confirmación',
        ycts: 'El monto ingresado no puede estar vacío'
    },
    addmy: {
        mywyi: 'Activos totales:',
        mywer: 'Ganancias de hoy:',
        mywsan: 'Los ingresos totales:',
        mywsi: 'Recompensas:',
        mywwu:'Saldo disponible:',
        mywliu:'Recargar',
        mywqi: 'Retirar',
        myba: 'Duración',
        sboyi: 'Regalo misterioso',
        sboer: 'caja',
        iboyi: 'WhatsApp',
        iboer: 'Telegrama',
        ibosan: 'Correo electrónico',
        xgpassts: 'Después de cambiar la contraseña, se necesitan 24 horas para iniciar el retiro',
        appyi: 'vincular cuenta',
        apper: 'Vinculaste exitosamente Whatsapp',
        tele: 'Has vinculado Telegram correctamente',
        yx: 'Has vinculado correctamente tu dirección de correo electrónico',
        appsan: 'Cuenta:',
        appsi: 'Revertir',
        appts: 'Ya agregaste una cuenta, si necesitas modificarla, contacta al administrador para su operación.',
        yxts: 'Después de hacer clic en enviar, no olvides revisar tu carpeta de spam. Si aún no recibe el código de verificación, inténtelo nuevamente más tarde.',
        mywts: 'Detalles del registro de facturas de esta semana',
        codeyi: 'Recompensas de intercambio de USDT',
        codeer: 'Recompensas de canje de OPT',
        duryi: 'Duración',
        durer: 'Duración en línea',
        dursan: 'La duración en línea es de 30 minutos.',
        addqhzt: 'Switch colors',
        addw: 'Blanco',
        addh: 'Negro',
        qdjy: 'Iniciar transacción'
    }
  }
  