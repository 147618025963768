<template>
  <!-- 忘记密码 -->
  <div class="forgot">
    <van-icon
        name="arrow-left"
        size="2rem"
        @click="$router.back()"
      />
    <div class="forgotbk">
      <div class="title">{{ $t("Forgot.title") }}</div>
      <van-form @submit="onSubmit">
        <div class="dingwei">
          <div class="Email">{{$t("add.addemail")}}</div>
          <van-field
            v-model="email"
            name="Email"
            :rules="rules.email"
          />
          <!-- <div class="quhao">{{ areacode }}</div> -->
        </div>
        <div>
          <div class="Email">{{ $t("Forgot.Newpassword") }}</div>
          <van-field
            v-model="newpassword"
            name="newpassword"
            :rules="rules.newpassword"
          />
        </div>
        <div>
          <div class="Email">{{ $t("Forgot.Confirmationpassword") }}</div>
          <van-field
            v-model="Confirmationpassword"
            name="Confirmationpassword"
            :rules="rules.Confirmationpassword"
          />
        </div>
        <div>
          <div class="Email">{{ $t("Forgot.code") }}</div>
          <div class="position">
            <van-field
              v-model="code"
              type="number"
              name="code"
              :rules="rules.code"
            />
            <div class="code" @click="Changecode" ref="code">
              {{ $t("Forgot.codes") }}
            </div>
          </div>
        </div>
        <!-- <div class="Forgot">Forgot password?</div> -->
        <div class="submit">
          <van-button round block type="info" native-type="submit">{{
            $t("Forgot.Determine")
          }}</van-button>
        </div>
      </van-form>
      <div class="tishi">
        *{{$t('add.addforbottom')}} <a :href="kfurl" >{{$t('add.addforbottomer')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { forgotpasswordapi, mcode, gethomeapi } from '@/api/index'   // obtaincodeapi
export default {
  name: 'ForGot',
  data () {
    return {
      email: '',
      newpassword: '',
      Confirmationpassword: '',
      code: '',
      settime: null,
      timeshow: false,
      codenumber: 0,
      rules: {},
      areacode: '+55', // 巴西电话区号
      kfurl: ''
    }
  },
  beforeDestroy () {
    clearInterval(this.settime)
  },
  created () {
    this.getkfurl()
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    // 监听语言切换
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  methods: {
    async getkfurl(){
      const res = await gethomeapi()
      // console.log(res)
      this.kfurl = res.data.service_link
    },
    // 校验规则
    setfromrules () {
      this.rules = {
        email: [
          {
            required: true,
            message: this.$t('Forgot.emailplaceholder'),
            trigger: ['blur', 'change']
          }
        ],
        newpassword: [
          {
            required: true,
            message: this.$t('Forgot.newpassword'),
            trigger: ['blur', 'change']
          }
        ],
        Confirmationpassword: [
          {
            required: true,
            message: this.$t('Forgot.rulespassword'),
            trigger: ['blur', 'change']
          },
          {
            validator: (value) => {
              return value === this.newpassword
            },
            message: this.$t('Forgot.torulespassword'),
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            required: true,
            message: this.$t('Forgot.rulescode'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    onSubmit (values) {
      // console.log('submit', values)
      this.Changenumber()
    },
    // 获取验证
    async obtaincode () {
      const datas = {
        mobile: this.email,
        event: 'changepwd'
      }
      const res = await mcode(datas)
      // console.log('code', res)
      // return false
      this.codenumber = res.code
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (this.codenumber === 1) {
        this.timeshow = true
        let times = 180
        this.settime = setInterval(() => {
          if (times <= 1) {
            clearInterval(this.settime)
            this.$refs.code.innerHTML = this.$t('Forgot.codes')
            this.timeshow = false
          } else {
            times = times - 1
            this.$refs.code.innerHTML = times + 's'
          }
        }, 1000)
      }
    },
    // 忘记密码
    async Changenumber () {
      const data = {
        mobile: this.email,
        newpassword: this.newpassword,
        captcha: this.code
      }
      const res = await forgotpasswordapi(data)
      // console.log('666', res)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (res.code === 1) {
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      }
    },
    // 验证
    Changecode () {
      if (this.timeshow) return
      if (this.email.trim() === '') {
        Toast({
          message: this.$t('Forgot.emailplaceholder'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      } else {
        this.obtaincode()
      }
    }
  }
}
</script>

<style lang='less' scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.forgot {
  padding: 0rem 1.6rem;
  padding-top: 2.5rem;
  // background: url("../assets/dibubj.png") no-repeat;
  // background-size: 100% 25rem;
  background-position: bottom;
  background-color: rgba(@baseColor,1);
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .forgotbk{
    background-color: rgba(@pageBgColor,1);
    box-shadow: 0px 3px 20px 1px rgba(214,211,197,0.2);
    border-radius: 1.3rem;
    padding: 1rem 1.5rem ;
    margin-top: 4.5rem;
    padding-bottom: 5rem;
  }
  .title {
    padding-top: 2.5rem;
    color:  rgba(@apptext);;
    font-size: 1.8rem;
    // margin-top: 4rem;
    margin-bottom: 3rem;
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border: 1px solid #f6f6f6;
    color: #333;
    border-radius: 1.2rem;
    box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.1);
    margin-top: 2rem;
    padding: 1.2rem 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    &:nth-child(1) {
      padding-right: 7rem;
    }
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #333;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #EFBB0B;
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.2rem;
  }
  ::v-deep .van-button {
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #EFBB0B;
    // border: 4px solid #EFBB0B;
    color: #333;
    font-weight: bold;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #BABFC6;
    margin: 1rem 0rem;
    font-size: 1.4rem;
  }
  // .dingwei {
  //   position: relative;
  //   ::v-deep .van-cell {
  //     padding-left: 6rem !important;
  //   }
  // }
  .quhao {
    width: 5rem;
    height: 4.4rem;
    position: absolute;
    color: #15fbb5;
    top: 60%;
    font-size: 1.5rem;
    left: 2rem;
  }
  .submit {
    margin-top: 4rem;
  }
  // 提示
  .tishi{
    color: rgba(@apptext);
    margin-top: 2.5rem;
    font-size: 1.3rem;
    a{
      color: red;
      font-weight: 700;
    }
  }
}
</style>
