<template>
  <!-- 我的 -->
  <div class="my">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
    <div style="min-height: 100vh;">
    <div class="mybk">
      <!-- 设置和客服-->
      <div class="mytop">
        <img src="../assets/mytop2.png" @click="toinstall" v-if="mytopurl == 0"/>
        <img src="../assets/mytop3.png" @click="toinstall" v-if="mytopurl == 1"/>
      </div>
      <div class="pos">
        <div class="userinfoone">
          <div style="margin-left: 2rem;">
            <div style="font-size: 2rem;">
              {{ user.nickname }}
              <!-- <span class="ids">ID:{{ user.id }}</span> -->
            </div>
            <div style="display: flex;align-items: center;margin-top: .5rem;">
              <div class="id_vip">ID: {{user.id}}</div>
              <div :class="[vip?'vipbox':'vipboxs']">
                <div class="vipleft">
                  <div>LV{{user.level}}</div>
                </div>
                <div class="vipright" v-show="vip">
                  2022-10-10
                </div>
              </div>
            </div>
          </div>
          <div class="avatar">
            <img :src="imgsrc" alt="" />
          </div>
          <!-- <div class="service">
            <img src="../assets/service.png" alt=""/>
          </div> -->
        </div>
      </div>
      <!-- 启动机器和倒计时-->
      <div class="qdmin">
        <div class="qdleft">
          <div>{{ $t("Receive.Remaining") }}:</div>
          <van-count-down :time="time" @finish="finish" />
        </div>
        <div class="qdright">
          <img src="../assets/mymin.png" class="btnimg" @click="startdevice" />
          <p>{{$t('addmy.qdjy')}}</p>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 8rem;border-top-left-radius: 2.5rem;border-top-right-radius: 2.5rem;" class="toppos">
      <div style="display: flex;flex-wrap: wrap;justify-content: center;">
        <div class="userinfo">
          <div class="Income">
            <div class="box">
              <div class="title">{{$t('addmy.mywyi')}}</div>
              <div class="green color">{{parseFloat(user.total_balance).toFixed(2)}}</div>
            </div>
            <div class="box">
              <div class="title">{{$t('addmy.mywer')}}</div>
              <div class="green color">{{parseFloat(user.today_earn).toFixed(2)}}</div>
            </div>
            <div class="box">
              <div class="title">{{$t('addmy.mywsan')}}</div>
              <div class="green color">{{parseFloat(user.profit_return).toFixed(2)}}</div>
            </div>
            <div class="box">
              <div class="title">{{$t('addmy.mywsi')}}</div>
              <div class="green color">{{parseFloat(user.award_profit).toFixed(2)}}</div>
            </div>
            <div class="box">
              <div class="title">{{$t('addmy.mywwu')}}</div>
              <div class="green color">{{parseFloat(user.money).toFixed(2)}}</div>
            </div>
          </div>
        </div> 
      </div>
      <!-- <div class="mywallet">
        <div class="qbtitle">
          <span>{{$t('addmy.mywyi')}}</span><span class="two">{{parseFloat(user.total_balance).toFixed(2)}}</span><span>USDT</span>
        </div>
        <div class="qbconer">
          <div class="cononeer">
            <div>{{$t('addmy.mywer')}}{{parseFloat(user.today_earn).toFixed(2)}}</div>
            <div>{{$t('addmy.mywsan')}}{{parseFloat(user.profit_return).toFixed(2)}}</div>
          </div>
          <div class="cononeer">
            <div>{{$t('addmy.mywsi')}}{{parseFloat(user.award_profit).toFixed(2)}}</div>
            <div>{{$t('addmy.mywwu')}}{{parseFloat(user.money).toFixed(2)}}</div>
          </div>
        </div>
        <div class="qbbtn">
          <div class="btn" @click="$router.push('/recharges')">
            <img src="../assets/wallet1.png" />
            {{$t('addmy.mywliu')}}
          </div>
          <div class="btn" @click="towithdraw">
            <img src="../assets/wallet2.png" />
            {{$t('addmy.mywqi')}}
          </div>
        </div>
      </div> -->
      <div class="Positioning">
        <!-- <div class="install" @click="toinstall">
          <img src="../assets/install.png" alt="" />
        </div> -->
        <div class="Notice" style="height: 2rem;">
          <div class="img">
            <img src="../assets/mynewimg.png" alt="" />
          </div>
          <van-swipe
            class="my-swipe"
            :autoplay="3000"
            indicator-color="black"
            :show-indicators="false">
            <van-swipe-item v-for="(i, v) in announcement" :key="v">{{
              i
            }}</van-swipe-item>
          </van-swipe>
        </div>
        <!-- 12.7 功能区-->
        <div class="status">
          <div class="con">
            <div class="one" @click="tomypurse">
              <img src="../assets/my1.png" class="conimg"/>
              <p class="conp">{{$t("my.wallet")}}</p>
            </div>
            <div class="one" @click="toproduct">
              <img src="../assets/my2.png" class="conimg"/>
              <p class="conp">{{$t("my.mykj")}}</p>
            </div>
            <div class="one" @click="tobangzhu">
              <img src="../assets/my3.png" class="conimg"/>
              <p class="conp">{{$t("my.center")}}</p>
            </div>
            <div class="one"  @click="toaddress">
              <img src="../assets/my4.png" class="conimg"/>
              <p class="conp">{{ $t("my.address") }}</p>
            </div>
          </div>
		  
          <div>
			  <div class="con">
			  			  <div class="one" @click="toyuebao">
			  			    <img src="../assets/my10.png" class="conimg"/>
			  			    <p class="conp">{{ $t("my.yueb") }}</p>
			  			  </div>
			    <div class="one" @click="toredemption">
			      <img src="../assets/my5.png" class="conimg"/>
			      <!-- <p class="conp">{{ $t("my.envelope") }}</p> -->
				  <p class="conp">Código Cupon</p>
			    </div>
			    <div class="one" @click="tovideo">
			      <img src="../assets/my6.png" class="conimg"/>
			      <p class="conp">{{ $t("my.Video") }}</p>
			    </div>
			   <!-- <div class="one" @click="appxiazai">
			      <img src="../assets/my7.png" class="conimg"/>
			      <p class="conp">{{ $t("my.Download") }}</p>
			    </div> -->
					<div class="one" @click="tocompanycetails">
					  <img src="../assets/my8.png" class="conimg"/>
					  <p class="conp">{{ $t("my.company") }}</p>
					</div>
			  </div>	
			  			  
			<!--  			<div class="con">
			  			  <div class="one" @click="tocompanycetails">
			  			    <img src="../assets/my8.png" class="conimg"/>
			  			    <p class="conp">{{ $t("my.company") }}</p>
			  			  </div>
			  			</div> -->
		  </div>
		  
		 <!-- <div v-show="open!='1'">
			  <div class="con">
			    <div class="one" @click="toredemption">
			      <img src="../assets/my5.png" class="conimg"/>
			      <p class="conp">{{ $t("my.envelope") }}</p>
			    </div>
			    <div class="one" @click="tovideo">
			      <img src="../assets/my6.png" class="conimg"/>
			      <p class="conp">{{ $t("my.Video") }}</p>
			    </div>
			    <div class="one" @click="appxiazai">
			      <img src="../assets/my7.png" class="conimg"/>
			      <p class="conp">{{ $t("my.Download") }}</p>
			    </div>
				<div class="one" @click="tocompanycetails">
				  <img src="../assets/my8.png" class="conimg"/>
				  <p class="conp">{{ $t("my.company") }}</p>
				</div>
			  </div>	
		  </div> -->
        </div>
        <!-- <div class="content">
          <div class="content-box" @click="tomypurse">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/purse.png" alt="" /></div>
              <div class="huang">{{ $t("my.wallet") }}</div>
            </div>
          </div>
          <div class="content-box" @click="toproduct">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/gpt.png" alt="" /></div>
              <div class="huang">{{ $t("my.mykj") }}</div>
            </div>
          </div>
          <div class="content-box" @click="tobangzhu">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/mark.png" alt="" /></div>
              <div class="huang">{{ $t("my.center") }}</div>
            </div>
          </div>
          <div class="content-box" @click="toaddress">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/address.png" alt="" /></div>
              <div class="huang">{{ $t("my.address") }}</div>
            </div>
          </div>
          <div class="content-box" @click="toredemption">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/envelope.png" alt="" style="height: 2.9rem;" /></div>
              <div class="huang">{{ $t("my.envelope") }}</div>
            </div>
          </div>
          <div class="content-box" @click="tovideo">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Video.png" alt="" /></div>
              <div class="huang">{{ $t("my.Video") }}</div>
            </div>
          </div>
          <div class="content-box" @click="appxiazai">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/app.png" alt="" /></div>
              <div class="huang">{{ $t("my.Download") }}</div>
            </div>
          </div>
          <div class="content-box" @click="tocompanycetails">
            <div class="myflex">
              <div class="img"><img src="../assets/company.png" alt="" /></div>
              <div class="huang">{{ $t("my.company") }}</div>
            </div>
          </div>
          <div class="content-box" @click="$router.push('/Activity')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Task.png" alt="" /></div>
              <div class="huang">{{$t('addhome.inbodyliu')}}</div>
            </div>
          </div>
          <div class="content-box" @click="$router.push('/Duration')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/timedrown.png" alt="" /></div>
              <div class="huang">{{$t('addmy.myba')}}</div>
            </div>
          </div>
        </div> -->
        <div class="bottom" @click="tologin">{{ $t("my.Sign") }}</div>
      </div>
    </div>
    </div>
    </van-pull-refresh>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { withdrawalapi, myuserapi, loginoutapi, startdeviceapi, startCountdownapi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'MY',
  data () {
    return {
      address: '',
      user: {},
      imgsrc: '',
      emali: '',
      announcement: [],
      isLoading: false,
      vip: 0,
      dwstatus: 0,
      mytopurl: 0,
      time: 0,
	   open:'',
	   isclick:1
    }
  },
  created () {
    if (localStorage.getItem('theme') == 'dark') {
      this.mytopurl = 1
      // console.log(this.kfurlqh)
    } else {
      this.mytopurl = 0
    }
    this.withdrawal()
    this.myuser()
    this.startCountdown()
  },
  methods: {
    onRefresh () {
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    },
    // 去帮助中心
    tobangzhu () {
      this.$router.push('/helpcenter')
    },
    // 去产品状态
    toproduct () {
      this.$router.push('/product')
    },
    // 去设置详情页面
    toinstall () {
      this.$router.push('/install')
    },
    // 去视频介绍
    tovideo () {
      this.$router.push('/video')
    },
    // 去我的钱包
    tomypurse () {
      this.$router.push('/mypurse')
    },
    // 去设置地址
    toaddress () {
      // console.log(this.emali, '邮箱')
      // return false
      this.$router.push({
        path: '/address'
      })
    },
    // 去红包页面
    toredemption () {
      this.$router.push('/redemption')
    },
    // 去公司介绍
    tocompanycetails () {
      this.$router.push('/companycetails')
    },
	// 去余额宝
	toyuebao () {
	  this.$router.push('/yuebao')
	},
    // 下载app
    appxiazai () {},
    // 退出登录
    async tologin () {
      const res = await loginoutapi()
      // console.log('111', res)
      localStorage.removeItem('token')
      sessionStorage.removeItem('show')
      sessionStorage.removeItem('gonggaoshow')
      localStorage.removeItem('lang')
      localStorage.removeItem('tink_var')
      localStorage.removeItem('is_email');
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      setTimeout(() => {
        this.$router.push('/login')
      }, 1500)
    },
    // 获取地址
    async withdrawal () {
      const res = await withdrawalapi()
      this.address = res.data.wallet_address
    },
    async myuser () {
      const res = await myuserapi()
      console.log(res, 'my')
	  this.open=res.data.open
      this.user = res.data.user
      this.user.award_profit = parseFloat(this.user.award_profit).toFixed(2)
      this.user.today_earn = parseFloat(this.user.today_earn).toFixed(2)
      this.user.profit_return = parseFloat(this.user.profit_return).toFixed(2)
      this.user.money = parseFloat(this.user.money).toFixed(2)
      this.imgsrc = res.data.user.avatar
      this.emali = res.data.user.email
      this.announcement = res.data.notice
      this.dwstatus = res.data.dw_status
    },
    // 去签到页面
    tosignin () {
      this.$router.push('/signin')
    },
    // 去体现
    towithdraw (){
      this.$router.push('/withdraw')
      // if(this.dwstatus == 1){
      //   this.$router.push('/withdraw')
      // }else{
      //   Toast({
      //     message: 'Please activate the machine first',
      //     duration: 2000,
      //     className: 'noticeWidth' // 所加样式名
      //   })
      //   this.$router.push('/mining')
      // }
    },
    // 开启设备
    async startdevice () {
      console.log(this.time)
      if (this.time <= 0) {
		  if(this.isclick==1) {
			  this.isclick=0
			  const res = await startdeviceapi()
			  Toast({
			    message: res.msg,
			    duration: 1200,
			    className: 'noticeWidth' // 所加样式名
			  })
				  // 开始倒计时
				  this.startCountdown()
		  }
       
      }
    },
    // 设备倒计时
    async startCountdown () {
      const res = await startCountdownapi()
      console.log(res, 'receive')
     
		  this.time = res.data.countdown_sec * 1000
		  // this.time = 30
			  this.isclick=1
		  console.log(this.time, 'time')
		  if (this.time >= 1) {
		    this.start = 0
		  } else {
		    this.start = 1
		  }
	  
    },
	finish() {
		// console.log('结束');
		// this.startCountdown()
	}
  }
}
</script>

<style lang="less" scoped>
@import '../style/theme/style.less'; // 引入主题样式文件
.my {
  // background-color: #060606;
  // background-image: url("../assets/indexback.png");
  background-color: rgba(@baseColor,1);
  background-size: 100% 90rem;
  background-repeat: no-repeat;
  width: 100%;
  // min-height: 100vh;
  .mybj {
    width: 100%;
  }
  .Positioning {
    padding: 0rem 1.6rem;
    // padding-top: 5rem;
    position: relative;
    .install {
      z-index: 99;
      width: 2rem;
      height: 1.9rem;
      position: absolute;
      top: -15rem;
      right: 1.6rem;
    }
    .Notice {
      display: flex;
      align-items: center;
      color: #fff !important;
      background-image: url('../assets/mynewbk.png');
      background-size: cover;
      background-repeat: no-repeat;
      padding: 3rem 0;
      padding-left: 1rem;
      // margin-top: 5.5rem;
      .img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1.3rem;
        margin-top: 0.4rem;
      }
      ::v-deep .my-swipe {
        width: 30rem;
      }
      ::v-deep .van-swipe-item {
        color: #fff;
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 0.6rem;
        // width: 30rem;
        // height: 2rem;
        // line-height: 2rem;
        text-align: center;
        // background-color: #39a9ed;
      }
    }
    //12.7
    .status{
      margin-top: 3rem;
      .con{
        box-shadow: 0px 3px 12px 1px rgba(0,0,0,0.08);
        background-color: rgba(@pageBgColor,1);
        color: rgba(@indextext);
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        margin-bottom: 2rem;
        .one{
          text-align: center;
          width: 25%;
          .conimg{
            width: 3rem;
            height: 3rem;
          }
          .conp{
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 1rem;
          }
        }
      }
    }
    .content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .content-box {
        width: 10.2rem;
        height: 10.2rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1);
        font-size: 1.2rem;
        color: #fff;
        margin-top: 1.8rem;
        .img {
          width: 2.91rem;
          height: 3.27rem;
          margin-left: 3.7rem;
          margin-top: 2rem;
          // margin: 0 auto;
          // margin-top: 1.8rem;
          // margin-bottom: 1.5rem;
        }
        .imgmargin{
          margin-top: 3rem;
        }
        // &:last-child {
        //   background-color: #060606;
        // }
      }
      .huang {
        color: #F6AD66;
        // margin-left: 1rem;
      }
    }
    .bottom {
      width: 100%;
      height: 4.4rem;
      background-color: #EFBB0B;
      text-align: center;
      line-height: 4.4rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
      border-radius: 10px;
      font-size: 1.6rem;
      font-weight: bold;
      color: rgba(@apptext);
    }
  }
}

/* 修改的*/
.mytop{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  // margin: 2.3rem 1.5rem;
}
.mytop img{
  width: 2.5rem;
  height: 2.5rem;
}
.mytop img:nth-child(2){
  height: 2.5rem;
  margin-left: 2.5rem;
}
.userinfoone{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // flex-direction: column;
  align-items: center;
  // position: absolute;
  color: rgba(@apptext);
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 3rem;
  .avatar {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    // background-color: #fff;
    // margin: 0 auto;
    // margin-top: -3.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
.color{
  color: #02D79A !important;
}
.mybk{
  // background-image: url('../assets/mybj.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  background-color: rgba(@baseColor,1);
  padding: 2.3rem 1.5rem;
  padding-bottom: 2.3rem;
  .qdmin{
    color: rgba(170, 170, 170, 1);
    display: flex;
    border: 2px solid #7F7F7F;
    border-radius: 10px 10px 10px 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding: 2.5rem 2rem;
    ::v-deep .van-count-down{
      color: rgba(11, 239, 223, 1) !important;
      font-size: 2.5rem;
      margin-top: 1rem;
    }
    .qdleft{
      font-size: 1.5rem;
    }
    .qdright{
      // width: 4rem;
      text-align: center;
      .btnimg{
        border-radius: 50%;
        width: 4rem;
        margin-bottom: .5rem;
      }
    }
  }
}
.userinfo {
      // position: absolute;
      // top: -7rem;
      // left: 1.6rem;
      width: 34.3rem;
      // // height: 15.85rem;
      // background-color: #fff;
      color: rgba(@apptext);
      border-radius: 1rem;
      margin-bottom: 1.5rem;
      padding: 1rem 0;
      .name {
        // display: flex;
        // justify-content: center;
        color: #fff;
        margin-top: 1.55rem;
        // margin-left: 1.5rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
        // span {
        //   font-size: 0.7rem;
        //   margin-left: 0.5rem;
        //   color: #ccc;
        // }
      }
      .avatar {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        // background-color: #fff;
        // margin: 0 auto;
        // margin-top: -3.5rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .service {
        position: absolute;
        top: 1.3rem;
        right: 1.3rem;
        width: 1.9rem;
        height: 1.88rem;
      }
      .name {
        color: rgba(@apptext);
        margin-top: 1.55rem;
        // margin-left: 1.5rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 600;
        
        // span {
        //   font-size: 0.7rem;
        //   margin-left: 0.5rem;
        //   color: #ccc;
        // }
      }
      .vipbox {
        width: 18rem;
        padding: .6rem 1.5rem;
        border-radius: 2rem;
        margin: 1rem auto;
        background: linear-gradient(130deg, #D1AE7A 0%, #FCE8CA 34%, #E2C49B 72%, #EDD9BC 100%);
        box-shadow: 0px 1px 3px 1px rgba(248,226,194,0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        .vipleft {
          display: flex;
          align-items: center;
          .vipimg {
          width: 2.4rem;
          margin-top: -.3rem;
          margin-right: .5rem;
        }
        }
      }
      
      .Income {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: #26272E;
        // margin-top: 2.5rem;
        padding: 1rem 0;
        padding-bottom: .5rem;
        border-radius: 2.5rem;
        .box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // width: 25%;
          // height: 7rem;
          text-align: center;
          color: rgba(@apptext);
          font-size: 1.6rem;
          padding: 0 0.5rem;
          padding-top: .5rem;
          // margin-top: 1rem;
          &:nth-child(3) {
            border-right: 0px solid #fff;
          }
          .title {
            color: rgba(@apptext);
            font-size: 1rem;
            margin-bottom: 0.9rem;
            height: 2.5rem;
          }
        }
        .green {
          color: #15FBB5;
        }
        .border {
          width: 1px;
          height: 6.5rem;
          background-color: rgba(255, 255, 255, 0.1);
          // margin-top: 2.4rem;
        }
      }
    }
    .daibi{
      display: flex;
      // justify-content: center;
      align-items: center;
      font-weight: bold;
      margin: .5rem 2rem;
      font-size: 1.5rem;
      border-top: 2px solid #f6f6f6;
      border-bottom: 2px solid #f6f6f6;
      // font-weight: 700;
      color: rgba(@apptext);
      padding: 1rem 0;
      .dbleft{
        margin-right: 10rem;
      } 
    }
    // 11.14 修改
    .id_vip{
      display: flex;
      justify-content: space-between;
      // margin: 0 5rem;
    }
    .vipboxs {
        width: 10.4rem;
        // padding: .6rem 1.5rem;
        padding-left: 3.5rem;
        padding-bottom: .2rem;
        height: 2.3rem;
        margin-left: 1rem;
        border-radius: 2rem;
        // margin: 1rem auto;
        // background: linear-gradient(130deg, #D1AE7A 0%, #FCE8CA 34%, #E2C49B 72%, #EDD9BC 100%);
        background-image: url('../assets/myvip.png');
        background-size: contain;
        background-repeat: no-repeat;
        // color: #fff;
        // box-shadow: 0px 1px 3px 1px  #939393;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        .vipleft {
          display: flex;
          align-items: center;
          .vipimg {
          width: 2.4rem;
          margin-top: -.3rem;
          margin-right: .3rem;
        }
        }
      }
      .toppos{
        position: relative;
        bottom: 2rem;
        background-color: rgba(@baseColor,1);
      }
/* 11.17修改我的钱包*/
.mywallet{
  margin: 2rem;
  margin-top: 0;
  background-image: url('../assets/chartbk.png');
  background-size: cover;
  background-repeat: no-repeat;
  // color: #fff;
  // box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
  border-radius: 10px 10px 10px 10px;
  // opacity: 1;
  padding: 1.5rem;
  color: #fff;
  font-size: 1.3rem;
  .qbtitle{
    .two{
      font-size: 1.5rem;
      font-weight: 700;
      color: #FFB31C;
      margin-right: .5rem;
    }
  }
  .qbcon{
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .conone{
      
      // padding-right: 4rem;
      margin-top: .7rem;
    }
  }
  .qbbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    .btn{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #02D7C1;
      img{
        width: 2rem;
        margin-right: .5rem;
      }
    }
  }
}

.qbconer{
  font-size: 10px;
  .cononeer{
    display: flex;
    margin-top: .5rem;
    justify-content: space-between;
    div{
      width: 100%;
    }
  }
}
</style>
