<template>
  <!-- 注册页面-->
  <div class="forgot">
    <van-icon
      name="arrow-left"
      size="2rem"
      @click="$router.push('/login')"
    />
    <div class="regbk">
      <div class="title">{{$t('add.addregtitle')}}</div>
      <van-form @submit="onSubmit">
        <div>
          <div class="Email">{{$t('register.username')}}</div>
          <van-field v-model="username" name="username" onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')" onpaste="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')" oncontextmenu = "value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
          :rules="rules.username" />
        </div>
        <div class="dingwei">
          <div class="Email">{{$t('register.phone')}}</div>
          <van-field v-model="number" type="number" name="number" :rules="rules.number" />
          <div class="quhao" @click="showPopover = true">{{areacode}}</div>
          <!-- 下拉选择区号-->
            <van-popover v-model="showPopover" :actions="actions" @select="onSelect" :offset="[50, 10]">
            </van-popover>
        </div>
        <div>
          <div class="Email">{{$t('register.password')}}</div>
          <van-field v-model="password" name="password" :rules="rules.password" />
        </div>
        <div>
          <div class="Email">{{$t('register.phonecode')}}</div>
          <div class="position">
            <van-field v-model="code" type="number" name="code" :rules="rules.code" />
            <div class="code" @click="Changecode" ref="code">{{$t('register.codes')}}</div>
          </div>
        </div>
        <div>
          <div class="Email">{{$t('register.code')}}</div>
          <van-field v-model="Invitation" name="Invitation" :disabled="true" />
        </div>
        <div class="submit">
          <van-button round block type="info" native-type="submit">{{$t('register.Determine')}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { obtaincodeapi, registerapi, getlogin } from '@/api/index'
const phonezz = '^(00){0,1}(47){1}{6,12}$'
export default {
  name: 'ForGot',
  data () {
    return {
      username: '',
      number: '',
      password: '',
      code: '',
      Invitation: '',
      settime: null,
      timeshow: false,
      codenumber: 0,
      rules: {},
      areacode: '+57',
      showPopover: false,
      actions: [{ text: '+57' }, { text: '+58' }],
    }
  },
  beforeDestroy () {
    clearInterval(this.settime)
  },
  created () {
    // const name = this.$route.query.invite_code
    // console.log(name)
    this.huoquInvitation()
  },
  mounted () {
    this.setfromrules()
  },
  watch: {
    // 监听语言切换
    '$i18n.locale' () {
      this.setfromrules()
    }
  },
  methods: {
    onSelect (e) {
      console.log(e)
      this.areacode = e.text
    },
    // 校验规则
    setfromrules () {
      this.rules = {
        username: [
          {
            required: true,
            message: this.$t('register.rulesusername'),
            trigger: ['blur', 'change']
          }
        ],
        number: [
          {
            required: true,
            message: this.$t('register.rulesnumber'),
            trigger: ['blur', 'change']
          },
          {
            pattren: phonezz,
            message: 'Please enter the correct phone number'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('register.rulespassword'),
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            required: true,
            message: this.$t('register.rulescode'),
            trigger: ['blur', 'change']
          }
        ]
        // Invitation: [
        //   {
        //     required: true,
        //     message: this.$t('register.rulesInvitation'),
        //     trigger: ['blur', 'change']
        //   }
        // ]
      }
    },
    // 有邀请码直接赋值没有为空
    huoquInvitation () {
      this.Invitation = this.$route.query.invite_code || ''
    },
    async onSubmit () {
      // console.log('submit', values)
      const data = {
        username: this.username,
        password: this.password,
        mobile: this.number,
        code: this.code,
        invite_code: this.Invitation
      }
      const res = await registerapi(data)
      // console.log(res)
      // return false
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      
      if (res.code === 1) {
        // setTimeout(() => { // 注册成功跳转到登录页面
        //   this.$router.push('/login')
        // }, 2000)
        
        // 注册成功直接跳转首页
        const logindata = {
          account: this.number,
          password: this.password
        }
        const loginres = await getlogin(logindata)
        if(loginres.code == 1){
          localStorage.setItem('token', loginres.data.userinfo.token)
          localStorage.setItem('is_email', loginres.data.is_email)
          if (localStorage.getItem('tink_var')) {
          // console.log(111)
          } else {
            localStorage.setItem('tink_var', 'en-us')
          }
          Toast({
            message: this.$t('add.addregts'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          setTimeout(() => {
            this.$router.push('/home')
          }, 1500)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      }
    },
    // 获取验证吗
    async obtaincode () {
      const datas = {
        mobile: this.number,
        event: 'register',
        area: '+52'
      }
      const res = await obtaincodeapi(datas)
      // console.log('code', res)
      this.codenumber = res.code
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (this.codenumber === 1) {
        this.timeshow = true
        let times = 180
        this.settime = setInterval(() => {
          if (times <= 1) {
            clearInterval(this.settime)
            this.$refs.code.innerHTML = 'code'
            this.timeshow = false
          } else {
            times = times - 1
            this.$refs.code.innerHTML = times + 's'
          }
        }, 1000)
      }
    },
    Changecode () {
      if (this.timeshow) return
      if (this.number.trim() === '') {
        Toast({
          message: this.$t('register.Changecode'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      } else {
        this.obtaincode()
      }
    }
  }
}
</script>

<style lang='less' scoped>
@import '../style/theme/style.less'; 
.forgot {
  padding: 0rem 1.6rem;
  padding-top: 2.5rem;
  background-color: rgba(@baseColor,1);
  width: 100%;
  min-height: 100vh;
  ::v-deep .van-icon{
    color: rgba(@apptext);
  }
  .title {
    padding-top: 1.5rem;
    color: rgba(@apptext);
    font-size: 1.8rem;
    // margin-top: 0rem;
    margin-bottom: 3rem;
  }
  .regbk{
    background-color: rgba(@pageBgColor,1);
    border-radius: 1.3rem;
    padding: 1rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    // border-radius: 1.2rem;
    box-shadow: 0px 3px 20px 1px rgba(0,0,0,0.1);
    margin-top: 2rem;
    padding: 1.2rem 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    &:nth-child(1) {
      padding-right: 7rem;
    }
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #000;
  }
  ::v-deep .position {
    position: relative;
  }
  .code {
    color: #EFBB0B;
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.2rem;
  }
  ::v-deep .van-button {
    background-color: #EFBB0B;
    border-radius: 2.2rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    width: 17rem;
    background-color: #EFBB0B;
    margin-top: 5rem;
    border: 4px solid #EFBB0B;
    font-weight: bold;
    color: #333;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #BABFC6;
    margin: 2rem 0rem;
    font-size: 1.4rem;
  }
  .dingwei {
    position: relative;
    ::v-deep .van-cell {
    padding-left: 6rem !important;
  }
  }
  .quhao {
    width: 5rem;
    height: 4.4rem;
    position: absolute;
    color: #EFBB0B;
    top: 55%;
    font-size: 1.5rem;
    left: 2rem;
  }
  .submit {
    // margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
}
</style>
